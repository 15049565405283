<template>
  <div class="meeting-apply-page wrapper page-wrap" v-if="meeting_book">
    <a-spin :spinning="loading">
      <a-row
        type="flex"
        justify="space-between"
        v-if="meeting_book"
        :gutter="40"
      >
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">房间名称</div>
            <div class="info-content">{{ meeting_book.meeting_name }}</div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">房间地址</div>
            <div class="info-content">
              {{ meeting_book.meeting_address }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">房间管理员</div>
            <div class="info-content">
              {{ meeting_book.meeting_contact_name }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">房间联系电话</div>
            <div class="info-content">
              {{ meeting_book.meeting_contact_phone }}
            </div>
          </div>
        </a-col>
        <a-col :span="24">
          <div class="flex flex-between info">
            <div class="info-title">预定说明</div>
            <div class="info-content">
              {{ meeting_book.meeting_introduction }}
            </div>
          </div>
        </a-col>
      </a-row>
      <div class="line"></div>
      <a-row
        type="flex"
        justify="space-between"
        v-if="meeting_book"
        :gutter="40"
      >
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">预定开始时间</div>
            <div class="info-content">
              {{ meeting_book.book_date }}&nbsp;&nbsp;{{
                meeting_book.book_start_time
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">预定结束时间</div>
            <div class="info-content">
              {{ meeting_book.book_date }}&nbsp;&nbsp;{{
                meeting_book.book_end_time
              }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">预定联系人</div>
            <div class="info-content">
              {{ meeting_book.book_contact_name }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">预定联系电话</div>
            <div class="info-content">
              {{ meeting_book.book_contact_phone }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">预定时间</div>
            <div class="info-content">
              {{ meeting_book.book_create_time }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="flex flex-between info">
            <div class="info-title">预定状态</div>
            <div
              :class="{
                'info-content': true,
                'info-active': meeting_book.book_status == 1
              }"
            >
              {{ getMeetStatus(meeting_book.book_status) }}
            </div>
          </div>
        </a-col>
      </a-row>
      <a-button
        v-if="meeting_book.book_status == 1|| meeting_book.book_status == 0"
        block
        size="large"
        type="primary"
        class="btn"
        :loading="loadingBtn"
        @click="updateMeetingBookStatusDataFun"
      >
        取消预订
      </a-button>
    </a-spin>
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref
} from "@vue/runtime-core";
import {
  updateMeetingBookStatusData,
  getMeetingData,
  getUserMeetingBookData
} from "@/api/main.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import url from "@/utils/url.js";
import { message } from "ant-design-vue";
import { encode, decode } from "js-base64";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "MeetingApplyDetail",
  components: {},
  setup() {
    const route = useRoute();
    const pageData = reactive({
      bookId: null,
      loadingBtn: false,
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData()),
      meeting_book: null
    });

    const getUserMeetingBookDataFun = () => {
      pageData.loading = true;
      getUserMeetingBookData({
        book_id: pageData.bookId,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.meeting_book = res.data.meeting_book;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const updateMeetingBookStatusDataFun = () => {
      pageData.loading = true;
      pageData.loadingBtn = true;
      updateMeetingBookStatusData({
        book_id: pageData.bookId,
        book_status: -1,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            message.success("操作成功");
            getUserMeetingBookDataFun();
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
          pageData.loadingBtn = false;
        });
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/meeting/applyDetail") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.bookId = query[0];
            getUserMeetingBookDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.meeting = null;
            message.error("暂无会议室信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    const getMeetStatus = (val) => {
      if (val == 1) {
        return "预订成功";
      } else if (val == -1) {
        return "取消预定";
      } else {
        return "等待审核";
      }
    };
    return {
      ...toRefs(pageData),
      updateMeetingBookStatusDataFun,
      getMeetStatus
    };
  }
});
</script>
<style lang="less">
.meeting-apply-page {
  margin-top: 40px;
  padding-bottom: 50px;
  box-sizing: border-box;
  .info {
    border-bottom: 1px solid #dfdfdf;
    height: 60px;
    .info-title {
      color: #212531;
      opacity: 0.7;
      font-size: 18px;
    }
    .info-content {
      color: #212531;
      font-size: 18px;
      span {
        width: 70px;
        height: 31px;
        background-color: #1276cb;
        border-radius: 15px;
        color: #ffffff;
        padding: 0 10px;
        margin-left: 10px;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .info-active {
      color: #1276cb;
    }
  }
  .line {
    background-color: #f3f5f8;
    height: 10px;
    margin-top: 30px;
  }
}
</style>
